.list {
    width: 100%;
    height: auto;
    padding: 180px 10% 120px 10%;
    box-sizing: border-box;
    font-family: "Avenir Book";
    /* background-color: #F1F2F6; */
    background-color: #F8F8FA;
}

.header {
    font-weight: bold;
    color: #121826;
    font-size: 36px;
    margin-bottom: 0px;
    font-family: "Montserrat Regular";
    text-align: center;
}

.intro {
    font-size: 18px;
    color: #4d5562;
    font-family: "Avenir Book";
    text-align: center;
    padding: 0 10%;
    margin-top: 32px;

}

.listBox {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.info {
    display: none;
    margin: 80px 0 0 0;
}


.visible {
    display: inherit;
}

.tagsBox {
    display: flex;
    align-items: center;
    justify-content: center;

}

.tag {
    font-size: 14px;
    background-color: #D8E3F2;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
    margin: 12px;
    padding: 8px;
}

@media(max-width: 800px) {
    .list {
        padding: 120px 5% 80px 5%;
    }

    .header {
        font-size: 24px;
    }

    .tagsBox {
        flex-wrap: wrap;

    }

    .tag {
        font-size: 12px;
        margin: 8px;
    }

    .intro {
        padding: 0px;
    }
}


@media(max-width: 475px) {
    .info {
        margin-top: 40px;
    }

    .intro {
        font-size: 16px;
    }
}