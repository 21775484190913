.propertyDetail {
    padding: 160px 10% 120px 10%;
    background-color: #F8F8FA;
    font-family: "Montserrat Bold";
}

.imagesBox {
    width: 100%;
    display: flex;
}

.mainImage {
    width: 500px;
    height: 600px;
    flex-grow: 1;
    background-size: cover;
    background-position: center;
    margin: 0 0px 12px 0px;
    cursor: pointer;
}

.secondaryImages {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.secondaryImages div {
    margin: 0 0px 12px 12px;
    cursor: pointer;
    width: 500px;
    height: 150px;
    flex-grow: 1;
    background-size: cover;
    background-position: center;
}

.propertyInfo {
    margin-top: 48px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.address {
    font-size: 18px;
    font-family: "Montserrat Regular";
    color: #4D5562;
}



.date {
    color: #4D5562;
    font-family: "Montserrat Bold";
}

.perks {
    display: flex;
    font-size: 14px;
    color: #4d5562;
    opacity: .7;
    margin-top: 32px;
}

.perks div {
    margin-right: 24px;
}

.perks img {
    width: 24px;
    margin-right: 8px;
}

.data {
    padding-right: 24px;
}

.btn {
    width: 180px;
    height: 40px;
    background-color: #E8B140;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Montserrat Regular";
    font-weight: bold;
    cursor: pointer;
    transition: all 1s ease;
    margin-right: 120px;
}

.btn:hover {
    background-color: #F4B800;
    box-shadow: 0px 8px 16px rgba(24, 24, 24, 0.25);
}

.priceBox {
    border: 1px solid #E7ECF3;
    padding: 8%;
    border-radius: 4px;
    margin-bottom: 16px;
}

.price {
    margin: 0px 0px 4px 0;
    color: #97BF5C;
    font-weight: bolder;
    font-size: 20px;
    font-family: "Montserrat Bold";
}

.priceBox p {
    margin: 0px;
}

.priceBox h4 {
    margin-top: 8px;
}

.microScale {
    transition: .4s all ease-in-out;
}

.microScale:hover {
    transform: scale(1.1);
}

.actionBox {
    display: flex;
    flex-direction: column;

}


@media(max-width: 1024px) {
    .mainImage {
        width: 600px;
    }

    .secondaryImages {
        width: 300px;
    }

    .secondaryImages div {
        width: 100%;
    }
}

@media(max-width: 800px) {
    .propertyDetail {
        padding: 120px 10% 80px 10%;

    }

    .imagesBox {
        flex-wrap: wrap;
    }

    .mainImage {
        width: 100%;
        height: 400px;
        margin: 8px;
    }

    .secondaryImages {
        width: 100%;
        flex-direction: row;
    }

    .secondaryImages div {
        width: 30%;
        margin: 8px;
    }

    .propertyInfo {
        margin: 0 8px;
        flex-wrap: wrap;

    }

    .btn {
        margin-right: 0px;
        margin-top: 64px;

    }

    .actionBox {
        width: 240px;
    }

    .priceBox {
        width: 240px;
        margin-top: 24px;
    }

    .btn {
        margin-top: 16px;

    }
}

@media(max-width: 550px) {
    .mainImage {
        height: 300px;
    }

    .secondaryImages div {
        height: 120px;
    }

}