@font-face {
    font-family: "Montserrat Italic";
    src: url("../../assets/Montserrat-Italic-VariableFont_wght.ttf");
}

@font-face {
    font-family: "Montserrat Regular";
    src: url("../../assets/Montserrat-VariableFont_wght.ttf");
}


@font-face {
    font-family: "Montserrat Bold";
    src: url("../../assets/Montserrat-Medium.ttf");
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(24px);

    }

    to {
        transform: translateY(0px);
    }
}

.welcome {
    width: 100%;
    height: 95vh;
    background-color: #121826;
    background-image: url("https://images.pexels.com/photos/258181/pexels-photo-258181.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");
    /* background-image: url("https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/portadaunikko.png?alt=media&token=5ab83822-362e-4833-9889-e846b3713c82"); */
    background-size: cover;
    background-position: botton;
}

.cover {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
    color: white;
    font-family: "Montserrat Regular";
    display: flex;
    align-items: center;
    padding: 5% 10%;
    box-sizing: border-box;
}

.cover h2 {
    font-family: "Montserrat Regular";
    font-size: 64px;
    width: 60%;
    margin-bottom: 16px;
}

.cover h3 {
    color: rgba(255, 255, 255, .9);
    font-family: "Montserrat Regular";
    font-size: 24px;
    width: 60%;
    font-weight: bolder;
    margin-top: 0px;
    margin-bottom: 48px;
    line-height: 1.7;
}

.cover h3 strong {
    color: #E8B140;
}

.btn {
    width: 180px;
    height: 40px;
    background-color: #E8B140;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Montserrat Regular";
    font-weight: bold;
    cursor: pointer;
    transition: all 1s ease;
}

.btn:hover {
    background-color: #DAA538;
    box-shadow: 0px 8px 16px rgba(24, 24, 24, 0.25);
}

.mouse {
    width: 48px;
    margin-top: 48px;
    animation-name: scroll;
    animation-duration: 3s;
    animation-iteration-count: infinite;

}

.services {
    width: 100%;
    min-height: 500px;
    background-color: #F8F8FA;
    text-align: center;
    padding: 10% 5%;
    box-sizing: border-box;
}

.clients {
    width: 100%;
    min-height: 500px;
    background-color: white;
    text-align: center;
    padding: 80px 10% 120px 10%;
    box-sizing: border-box;
}

.partners {
    width: 100%;
    height: 500px;
    background-color: #F8F8FA;
    text-align: center;
    padding: 80px 10%;
    box-sizing: border-box;
}

.cardBox {
    display: flex;
    justify-content: center;
}

.cardBox a {
    color: #121826 !important;
}

.card {
    width: 400px;
    height: 360px;
    background-color: white;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 8px 16px 0px rgba(204, 204, 204, 0.25);
    margin: 24px;
    font-family: "Montserrat Regular";

}

.cardImage {
    height: 82%;
    overflow: hidden;

}

.cardImage img:hover {
    transform: scale(1.1);
}

.cardImage img {
    height: 100%;
    object-fit: cover;
    transition: all 1s ease;

}

.title {
    font-family: "Montserrat Regular";
    font-size: 36px;
    text-align: center;
    color: #121826;

}

.text {
    font-size: 18px;
    color: #4d5562;
    font-family: "Avenir Book";
    text-align: left;
    margin-top: 32px;
}

.about {
    width: 100%;
    padding: 4% 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.about h2 {
    font-family: "Montserrat Regular";
    font-size: 36px;
    margin-bottom: 16px;
    color: #121826;

}

.about p {
    font-size: 18px;
    font-family: "Montserrat Regular";
    color: #4D5562;
}

.aboutInfo {
    width: 400px;
    flex-grow: 1;
    padding: 5% 10%;
    box-sizing: border-box;
}

.aboutImages {
    width: 400px;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
}

.aboutImages div {
    width: 240px;
    height: 240px;
    background-size: cover;
    flex-grow: 1;
    border: 10px solid white;
    border-radius: 16px;
    transition: all .5s ease;
    overflow: hidden;
}

.aboutImages div:hover {
    transform: scale(0.9);
}

/* .aboutImages img {
    object-fit: cover;
    width: 100%;
    height: 500%;
} */

.steper {
    width: 100%;
    background-color: #F8F8FA;
    padding: 80px 10%;
    box-sizing: border-box;

}

.steperBox {
    padding: 80px 0px;
    display: flex;
    justify-content: space-around;
}

/* .stepCard {
    width: 300px;
    height: 180px;
    background-color: white;
    text-align: center;
    box-sizing: border-box;
}

.stepInfo {
    padding: 0 8%;
    margin-top: -24px;
}

.circle {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #12385E;
    color: white;
    position: relative;
    left: 118px;
    bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: 500;
} */

.surface {
    width: 72px;
    height: 72px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.surface img {
    width: 60%;
}

.cardS {
    width: 240px;
    height: 200px;
    text-align: center;
    font-family: "Montserrat Regular";
}

.cardTitle {
    color: #121826;

}

.cardText {
    color: #4D5562;

}

.line {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.circleY {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #F3BA43;
    margin-right: 4px;
}

.circleM {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #9779F6;
    margin-left: 4px;
}

.line hr {
    width: 100px;
    border-top: none;
    border-bottom: 1.5px dashed #cccccc;
}

.boxComments {
    display: flex;
    justify-content: space-around;
    margin-top: 160px;
    color: #4D5562;
    font-family: "Montserrat Regular";
}

.boxComments h3 {
    color: #121826;
}

.boxComments img {
    width: 24px;
}

.comment {
    width: 260px;
    background-color: white;
    padding: 0 4% 2% 4%;
    border-radius: 16px;
    box-shadow: 0px 8px 16px 0px rgba(204, 204, 204, 0.25);
}

.photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-color: red;
    margin: 0 auto;
    position: relative;
    top: -40px;
    background-image: url("https://images.pexels.com/photos/925786/pexels-photo-925786.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
}

.content {
    margin-top: -16px;
}

.boxPartners {
    display: flex;
    flex-wrap: wrap;
    height: 240px;
    align-items: center;
    justify-content: space-around;
}

.partners img {
    width: 200px;
    filter: grayscale(100%);
}

@media (max-width:1024px) {
    .aboutImages div {
        width: 120px;
        height: 120px;
    }

    .comment {
        margin-right: 12px;
        margin-left: 12px;
    }
}

@media (max-width:800px) {
    .cover h2 {
        font-size: 36px;
        width: 80%;
    }

    .cardBox {
        flex-wrap: wrap;
    }

    .aboutImages {
        padding: 0px 8%;
        margin-top: 48px;
    }

    .aboutInfo {
        padding: 0px 10%;
    }

    .about {
        padding: 80px 0px;
        box-sizing: border-box;
    }

    .boxComments {
        flex-wrap: wrap;
    }

    .comment {
        margin-bottom: 80px;
    }

    .clients {
        padding-bottom: 0px;
    }

    .boxPartners {
        height: auto;
    }

    .partners {
        height: auto;
    }

    .line hr {
        width: 50px;
    }
}

@media (max-width:475px) {
    .cover h2 {
        font-size: 36px;
        width: 100%;
        text-align: center;
        margin-bottom: 80px;
    }


    .cover h3 {
        font-size: 18px;
        width: 100%;
    }

    .cover div {
        justify-content: center;
        text-align: center;
    }

    .steperBox {
        flex-wrap: wrap;
        padding-bottom: 0px;
    }

    .line {
        height: 80px;
    }

    .line hr {
        width: 100px;
    }

    .content p {
        font-size: 14px;
    }
}