.about {
    width: 100%;
    padding: 180px 10% 120px 10%;
    height: auto;
    box-sizing: border-box;
    font-family: "Avenir Book";
    background-color: #F8F8FA;
    text-align: center;
}

.header {
    font-weight: bold;
    color: #121826;
    font-size: 36px;
    margin-bottom: 0px;
    font-family: "Montserrat Regular";
    text-align: center;
}

.text {
    font-size: 18px;
    color: #4d5562;
    font-family: "Avenir Book";
    text-align: center;
    padding: 0 10%;
    margin-top: 32px;
}

.collage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
}

.small {
    width: 200px;
    height: 280px;
    border-radius: 12px;
    margin: 16px;
    background-size: cover;
    background-position: center;
}

.tall {
    width: 200px;
    height: 400px;
    margin: 16px;
    border-radius: 12px;
    background-size: cover;

    background-position: center;
    /* margin: 8px;
    box-sizing: border-box; */
}

.info {
    padding: 200px 0px 0px 0;
}

.info img {
    width: 500px;
    margin: 64px 32px;
    border-radius: 16px;
}

@media (max-width:1024px) {
    .about {
        padding: 180px 5% 80px 5%;
    }

    .text {
        padding: 0 5%;
    }

    .small {
        width: 120px;
        height: 180px;
    }

    .tall {
        width: 120px;
        height: 200px;
    }

    .collage {
        margin-top: 80px;
    }

    .info {
        padding: 80px 0px 0 0px;
    }

    .info img {
        width: 320px;
    }
}

@media (max-width:800px) {
    .about {
        padding: 120px 5% 80px 5%;
    }

    .small {
        width: 100px;
        height: 180px;
        margin: 8px;
    }

    .tall {
        width: 100px;
        height: 200px;
        margin: 8px;
    }

    .info img {
        width: 240px;
    }

    .header {
        font-size: 24px;
    }
}

@media (max-width:650px) {
    .about {
        padding: 120px 5% 80px 5%;
    }

    .small {
        width: 80px;
    }

    .tall {
        width: 80px;
    }
}

@media (max-width:475px) {
    .small {
        width: 80px;
    }

    .tall {
        width: 80px;
        display: none;
    }

    .collage {
        margin-top: 48px;
    }

    .info img {
        width: 80%;
        margin: 24px auto;
    }

    .text {
        font-size: 16px;
    }

    .about p {
        font-size: 16px;
    }
}