.login {
    width: 100%;
    height: 100vh;
    background-color: #121826;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 5px solid (255, 255, 255, .5);
}

.loginCard {
    margin-top: 120px;
    width: 500px;
    height: 700px;
    background-color: white;
    border-radius: 16px;
    padding: 3% 2% 5%;
    box-sizing: border-box;
    text-align: center;
    font-family: "Montserrat Regular";

}

.loginCard img {
    width: 120px;
}

.loginCard p {
    font-size: 18px;
    font-family: "Montserrat Regular";
    color: #4D5562;
}

.form {
    text-align: left;
    margin-top: 32px;
}

.form div {}

.form input {
    width: 100%;
    height: 40px;
    margin-bottom: 32px;
    margin-top: 8px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding-left: 12px;
    box-sizing: border-box;
}

.form input:focus,
.form input:active {
    border: 1px solid #E8B140;
}

.form input::placeholder {
    opacity: .3;
}

.btn {
    width: 100%;
    height: 40px;
    background-color: #E8B140;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Montserrat Regular";
    font-weight: bold;
    cursor: pointer;
    transition: all 1s ease;
    margin-top: 64px;
}

.btn:disabled{
    opacity:.5;
    cursor: not-allowed;
}

.btn:hover {
    background-color: #F4B800;
    box-shadow: 0px 8px 16px rgba(24, 24, 24, 0.25);
}