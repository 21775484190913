.contact {
    width: 100%;
    height: 100vh;
    padding: 120px 10% 40px 10%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #F8F8FA;
    color: #4D5562 !important;
    justify-content: space-between;
}

.contactInfo,
.map {
    width: 400px;
    flex-grow: 1;
}

.contactInfo {
    font-family: "Montserrat Regular";
    font-size: 24px;
    display: flex;
    flex-direction: column;
    color: #121826;
}

.contact h2 {
    font-size: 36px;

}

.contact a {
    text-decoration: none;
    color: #4D5562;
}

.contactInfo div {
    margin-bottom: 48px 0 48px 0px;
}

.map {
    display: flex;
    justify-content: flex-end;
}

.btnSecondary {
    width: 180px;
    height: 40px;
    background-color: rgb(151, 121, 246);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Montserrat Regular";
    font-weight: bold;
    cursor: pointer;
    transition: all 1s ease;
}

.btnSecondary:hover {
    background-color: rgb(151, 121, 246);
    box-shadow: 0px 8px 16px rgba(24, 24, 24, 0.25);
}

.map iframe {
    height: 70vh;
    background-size: cover;
    border-radius: 16px;
    width: 100%;
}

@media(max-width: 800px) {
    .contact {
        padding: 120px 5% 80px 5%;
    }

    .contact h2 {
        font-size: 24px;

    }

    .contact h3 {
        font-size: 18px;
    }

    .map iframe {
        width: 100%;
        height: 400px;
        margin-top: 40px;
    }
}