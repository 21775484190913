.navbarTop {
    width: 100%;
    height: 70px;
    padding: 0 10%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background-color: white;
    z-index: 1;
    position: fixed;
    font-family: "Montserrat Regular";

}

.navbarTop img {
    height: 80px;
}


.dashboard {
    width: 100%;
    padding: 120px 10% 120px;
    box-sizing: border-box;
    font-family: "Montserrat Regular";
    background-color: #F8F8FA;

}

.header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid #E7ECF3;
}

/* 
.chip {
    display: flex;
    justify-content: center;
    align-items: center;
} */


.listBox {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.info {
    display: none;
    margin: 40px 0 0 0;
}


.visible {
    display: inherit;
}

.btn {
    width: 180px;
    height: 40px;
    background-color: #E8B140;
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    font-family: "Montserrat Regular";
    font-weight: bold;
    cursor: pointer;
    transition: all 1s ease;
    margin-left: 24px;
}

.btn:hover {
    background-color: #DAA538;
    box-shadow: 0px 8px 16px rgba(24, 24, 24, 0.25);
}

.selectBox {

    display: flex;
    justify-content: center;
    /* position: absolute;
    float: right;
    right: 12%; */
}



.select {
    height: 40px;
    border-radius: 20px;
    width: 30%;
    margin-top: 32px;
    border: 1px solid #cccccc;
    padding: 0 16px;
    background-color: transparent;
}

.selectBox select {
    padding-right: 16px;
}

@media (max-width:650px) {
    .header {
        flex-wrap: wrap;
    }

    .btn {
        margin-left: 0px;
    }

    .select {
        width: 90%;
    }

}