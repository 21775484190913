.modal {
    width: 100%;
    padding: 120px 10% 120px;
    box-sizing: border-box;
    font-family: "Montserrat Regular";
    background-color: #F8F8FA;
}

.card {
    width: 80%;
    margin: 0 auto;
    background-color: white;
    padding: 4%;
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: 0px 8px 16px rgba(77, 85, 98, 0.1);

}

.float {
    position: relative;
    float: right;
    top: -24px;
    right: -24px;
}

.form {
    display: flex;
    flex-wrap: wrap;
}


.inputContainer {
    width: 50%;
    margin-bottom: 32px;
}

.selectInput {
    width: 50%;
}

.boxField {
    width: 50%;
}

.fieldContainer {
    width: 90%;
    margin-bottom: 32px;
}

.fieldContainer input {
    display: block;
    width: 100%;
    height: 40px;
    margin-top: 8px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding-left: 12px;
    box-sizing: border-box;

}

.input {
    display: block;
    width: 90%;
    height: 40px;
    margin-top: 8px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding-left: 12px;
    box-sizing: border-box;
}

.actions {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.inputFile {
    display: block;
    width: 90%;
    height: 40px;
    margin-bottom: 32px;
    margin-top: 8px;
    box-sizing: border-box;
}

.form input::placeholder {
    opacity: .3;
}

.btn {
    width: 120px;
    height: 40px;
    background-color: #9779F6;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Montserrat Regular";
    font-weight: bold;
    cursor: pointer;
    transition: all .3s ease;
    margin-left: 24px;
}

.btnDelete {
    width: 120px;
    height: 40px;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Montserrat Regular";
    font-weight: bold;
    cursor: pointer;
    /* transition: all .3s ease; */
    margin-left: 24px;
    background-color: red;
}

.btn:disabled,
.btnDelete:disabled {
    opacity: .5;
    cursor: not-allowed;
}

.btn:hover {
    background-color: #8966FA;
    box-shadow: 0px 8px 16px rgba(24, 24, 24, 0.25);
}

.btnBox {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.spinner {
    width: 24px;
    animation: turn .3s infinite steps(8);
}


.selectInput {
    display: block;
}

.selectInput label {
    display: block;
}

.selectInput select {
    display: block;
    width: 90%;
    height: 40px;
    margin-top: 8px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding-left: 12px;
    box-sizing: border-box;
}

.picsBox {
    display: flex;
    flex-wrap: wrap;
}

@keyframes turn {
    50% {
        transform: rotate(360deg)
    }
}

@media (max-width:650px) {
    .modal {
        padding: 80px 5%;
    }

    .card {
        width: 100%;

    }

    .inputContainer {
        width: 100%;
    }

    .selectInput,
    .boxField {
        width: 100%;
    }

}