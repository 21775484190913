.card {
    width: 320px;
    height: 420px;
    background-color: white;
    /* box-shadow: 0px 4px 8px rgba(77, 85, 98, 0.05); */
    border-radius: 16px;
    box-sizing: border-box;
    margin: 24px;
    cursor: pointer;
    border: 1px solid #F3F0F0;
    position: relative;
}

a {
    text-decoration: none;
}

.img {
    width: 100%;
    height: 240px;
    border-radius: 16px 16px 0 0;
    background-size: cover;
    background-position: center;
    overflow: hidden;

}

.chip {
    background-color: #BB333C;
    position: absolute;
    color: white;
    z-index: 10;
    padding: 4px;
    border-radius: 4px;
    right: 16px;
    top: 16px;
}

.img img {
    width: 100%;
    height: 240px;
    transition: all 1s ease;
    object-fit: cover;
}

.cardContent {
    padding: 2% 6%;
}

.title {
    font-size: 16px;
    color: #121826;
    margin-bottom: 0px;
}

.address {
    font-size: 16px;
    color: #4d5562;
    margin-top: 4px;
    margin-bottom: 0px;
}

.perks {
    display: flex;
    font-size: 14px;
    color: #4d5562;
    opacity: .7;

}

.perks div {
    margin-right: 24px;
}

.perks img {
    width: 24px;
    margin-right: 8px;
}

.price {
    margin: 12px 0px 4px 0;
    color: #97BF5C;
    font-weight: bolder;
    font-family: "Montserrat Bold";
}

.date {
    color: #4d5562;
}

.card:hover {
    box-shadow: 0px 8px 16px rgba(77, 85, 98, 0.1);
    transition: all 1s ease;
}

.card:hover .img img {
    transform: scale(1.2);
}

@media(max-width: 960px) {
    .card {
        width: 320px;
        height: 320px;
    }

    .img {
        height: 140px;
    }
}

@media(max-width: 475px) {
    .card {
        width: 90%;
        height: 320px;
    }

    .img {
        height: 140px;
    }
}