.navbarTop {
    width: 100%;
    height: 70px;
    padding: 0 10%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    position: fixed;
    background-color: white;
    z-index: 1;
    position: fixed;
}

.navbarTop img {
    height: 80px;
}

.tabs {
    display: flex;
    align-items: center;
}

.tabs a {
    text-decoration: none !important;
}

.navbarTop h3 {
    margin: 0 16px;
    font-size: 18px;
    color: #121826;
    font-family: "Montserrat Regular";
}

.navbarTop h3:hover {
    color: #12385E;
    cursor: pointer;
}

.btn {
    width: 120px;
    height: 40px;
    background-color: #E8B140;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Montserrat Regular";
    font-weight: bold;
    cursor: pointer;
    transition: all 1s ease;
    margin-left: 24px;
}

.btn:hover {
    background-color: #DAA538;
    box-shadow: 0px 8px 16px rgba(24, 24, 24, 0.25);
}

.mobile {
    display: none;
}

.menuIcon {
    width: 32px;
}


.menu-drop {
    display: none;
    right: -400px;
    transition: all .3s ease;
}


@media(max-width: 800px) {
    .mobile {
        display: flex;
        font-size: 24px;
    }

    .menuDropActive {
        right: 0;
        height: 100vh;
        padding: 24px 0 48px 24px;
        width: 80%;
        top: 0px;
        position: absolute;
        background-color: white;
        color: #121826;
    }

    .logoIcon {
        margin-left: 24px;
    }

    .menuDropActive h3 {
        margin: 48px 24px;
    }

    .closeIcon {
        float: right;
        width: 32px;
        margin-right: 48px;
        margin-top: -24px;
    }

    .tabs {
        display: none;
    }

    .cover h1 {
        line-height: 80px;
    }

}

.mobile-nav h3:active {
    opacity: .6;
}

/* 
@media(max-width: 1040px) {
    .navbarTop {
        padding: 0 5%;
    }
}

@media (max-width:800px) {
    .mobile {
        display: inherit;
    }

    .tabs {
        display: none;
    }
}

@media(max-width: 475px) {
    .hidden {
        display: none;
    }

    .navbarTop h3 {
        font-size: 16px;
    }
}

@media(max-width: 345px) {
    .navbarTop h3 {
        margin: 0 8px;

    }
} */