.fund {
    width: 100%;
    height: auto;
    padding: 180px 10% 120px 10%;
    box-sizing: border-box;
    font-family: "Avenir Book";
    background-color: #F8F8FA;
}

.header {
    font-weight: bold;
    color: #121826;
    font-size: 36px;
    margin-bottom: 0px;
    font-family: "Montserrat Regular";
    text-align: center;
    margin-bottom: 80px;
}

.box,
.boxi {
    display: flex;
    justify-content: space-between;
    margin-bottom: 120px;

}

.box div,
.boxi div {
    width: 50%;
}

.box img,
.boxi img {
    width: 500px;
    height: 400px;
}

.fund h2 {
    font-size: 36px;
    color: #121826;
}

.box p,
.boxi p {
    font-size: 20px;
    line-height: 24px;
    color: #4D5562;
}


.card {
    width: 360px;
    flex-grow: 1;
    border: 1px solid rgba(217, 217, 217, .7);
    padding: 2%;
    border-radius: 40px;
    box-sizing: border-box;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
}

.card:hover {
    background-color: rgba(217, 217, 217, .3);
}

.card .icon {
    width: 80px;
}

.btn {
    width: 180px;
    height: 40px;
    background-color: #E8B140;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Montserrat Regular";
    font-weight: bold;
    cursor: pointer;
    transition: all 1s ease;
    margin-right: 120px;
}

.btn:hover {
    background-color: #F4B800;
    box-shadow: 0px 8px 16px rgba(24, 24, 24, 0.25);
}




.companiesSteps {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: space-between;
}

.steper {
    margin-top: 24px;
    display: flex;
    justify-content: space-around;

}

.stepes {
    width: 350px;
    flex-grow: 1;
    margin: 16px 24px;
    z-index: 0;
}

.stepsh {
    margin-top: 32px;
    font-size: 20px;
    text-align: center;
}



.steperBox {
    padding: 80px 0px;
    display: flex;
    justify-content: space-around;
}

.surface {
    width: 72px;
    height: 72px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.surface img {
    width: 60%;
}

/* 
.cardS {
    width: 240px;
    height: 200px;
    text-align: center;
    font-family: "Montserrat Regular";
}

.cardTitle {
    color: #121826;

}

.cardText {
    color: #4D5562;

}

.line {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.circleY {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #F3BA43;
    margin-right: 4px;
}

.circleM {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #9779F6;
    margin-left: 4px;
}

.line hr {
    width: 100px;
    border-top: none;
    border-bottom: 1.5px dashed #cccccc;
} */

@media(max-width: 800px) {
    .fund {
        padding: 120px 5% 80px 5%;
    }

    .header {
        font-size: 24px;
        margin-bottom: 64px;
    }

    .fund h3 {
        font-size: 18px;
    }

    .box,
    .boxi {
        flex-wrap: wrap;
    }

    .box div,
    .boxi div {
        width: 100%;
    }

    .box img,
    .boxi img {
        width: 100%;
        height: auto;
        margin-top: 48px;
    }
}

@media (max-width:525px) {
    .steperBox {
        flex-wrap: wrap;
        padding-bottom: 0px;
    }

    .line {
        height: 80px;
    }

    .line hr {
        width: 100px;
    }

    .boxi {
        flex-direction: column-reverse
    }

}